import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class AboutCards {

  constructor (el) {
    this.el = el
    this.items = this.el.querySelectorAll('.js-about-cards-item')
    this.tl = []
  }

  init () {

    let mm = gsap.matchMedia()

    gsap.utils.toArray(this.items).forEach((el, i) => {

      const caption = el.querySelector('.js-about-cards-caption')
      const image = el.querySelector('.js-about-cards-image')

      gsap.set(caption, { y: 0 })
      gsap.set(image, { scale: 1.2 })

      mm.add("(min-width: 1281px)", () => {

        this.tl[i] = gsap.timeline(
          {
            scrollTrigger: {
              scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
              trigger: el,
              scrub: true,
              start: 'top bottom',
              end: 'bottom top'
            }
          }
        )

        this.tl[i]
          .to(caption, { y: '50%', ease: 'none', force3D: true }, 0)
          .to(image, { scale: 1, ease: 'none', force3D: true }, 0)
  
      })
      
      mm.add("(min-width: 768px) and (max-width: 1280px)", () => {

        this.tl[i] = gsap.timeline(
          {
            scrollTrigger: {
              scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
              trigger: el,
              scrub: true,
              start: 'top bottom',
              end: 'bottom top'
            }
          }
        )

        this.tl[i]
          .to(caption, { y: '25%', ease: 'none', force3D: true }, 0)
          .to(image, { scale: 1, ease: 'none', force3D: true }, 0)
  
      })

      mm.add("(max-width: 767px)", () => {
        this.destroy()
        gsap.set(caption, { y: 0 })
        gsap.set(image, { scale: 1 })
      })

    })

  }

  destroy () {
    if (this.tl.length) {
      this.tl.forEach(el => {
        el.kill()
        el = null
      })
      this.tl = []
    }
  }

}
