import Swiper, { Pagination } from 'swiper'
Swiper.use([Pagination])

export default class Stages {
  constructor (el) {
    this.el = el
    this.slider = null
    this.pagination = '.stages__pagination'

    this.resizeHandler = () => this.onResize()
  }

  init () {
    this.initEvents()
    this.onResize()
  }

  initEvents () {
    window.addEventListener('resize', this.resizeHandler)
  }

  removeEvents () {
    window.removeEventListener('resize', this.resizeHandler)
  }

  destroy () {
    this.sliderDestroy()
    this.removeEvents()
  }

  onResize () {
    if (window.innerWidth > 767) {
      this.sliderDestroy()
    } else {
      this.sliderInit()
    }
  }

  sliderInit () {
    if (this.slider) return;

    this.slider = new Swiper(this.el, {
      slidesPerView: 1,
      spaceBetween: 16,
      autoHeight: true,
      pagination: {
        el: this.pagination
      }
    })
  }

  sliderDestroy () {
    if (!this.slider) return;
    this.slider.destroy()
    this.slider = null
  }
}
