import Lottie from "lottie-web"

export default class Lines {
  constructor (el) {
    this.el = el
    this.path = this.el.getAttribute('data-path')
    this.lottie = this.el.querySelector('.js-lines-lottie')
    this.animation = null
  }

  init () {
    this.animation = Lottie.loadAnimation({
      container: this.lottie,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      path: this.path
    })
  }

  play () {
    this.animation.play()
  }

  destroy () {
    if (this.animation) {
      this.animation.destroy()
      this.animation = null
    }
  }
}
