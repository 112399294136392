import gsap from "gsap";

export default class CustomCursor {

  constructor (el) {
    this.el = el
    this.items = gsap.utils.toArray('.js-custom-cursor-item')
    this.text = this.el.querySelector('.js-custom-cursor-text')
    this.growLinks = document.querySelectorAll('[data-grow-cursor]')
    this.viewLinks = document.querySelectorAll('[data-view-cursor]')
    this.isActive = false

    this.initDefaults()
  }

  initDefaults () {
    this.initEvents()
  }

  initEvents () {
    window.addEventListener('mousemove', e => this.move(e))
    this.addLinksEvents()
  }

  addLinksEvents () {
    this.growLinks.forEach( el => {
      el.addEventListener('mouseenter', e => this.onGrowEnter(e))
      el.addEventListener('mouseleave', e => this.onGrowLeave(e))
    } )
    this.viewLinks.forEach( el => {
      el.addEventListener('mouseenter', e => this.onViewEnter(e))
      el.addEventListener('mouseleave', e => this.onViewLeave(e))
    } )
  }

  removeLinksEvents () {
    this.growLinks.forEach( el => {
      el.removeEventListener('mouseenter', e => this.onGrowEnter(e))
      el.removeEventListener('mouseleave', e => this.onGrowLeave(e))
    } )
    this.viewLinks.forEach( el => {
      el.removeEventListener('mouseenter', e => this.onViewEnter(e))
      el.removeEventListener('mouseleave', e => this.onViewLeave(e))
    } )
  }

  refresh () {
    this.onGrowLeave()
    this.onViewLeave()

    this.growLinks = document.querySelectorAll('[data-grow-cursor]')
    this.viewLinks = document.querySelectorAll('[data-view-cursor]')
    this.removeLinksEvents()
    this.addLinksEvents()
  }

  move (e) {
    const x = e.clientX
    const y = e.clientY

    if (!this.isActive) {
      this.items.forEach( (el, i) => {
        gsap.set(el, {
          x: x,
          y: y,
          force3D: false
        })
      } )
      gsap.to(this.el, 0.6, { opacity: 1 })
      this.isActive = true
    }

    this.items.forEach( (el, i) => {

      const duration = 0.3 + ( 0.04 * i )

      gsap.to(el, duration, {
        x: x,
        y: y,
        ease: 'ease',
        force3D: false
      })

    } )

    gsap.to(this.text, 0.3, {
      x: x,
      y: y,
      ease: 'ease',
      force3D: false
    })
  }

  onGrowEnter (e) {
    this.items.forEach( (el, i) => {

      const duration = 0.3 + ( 0.04 * i )

      gsap.to(el, duration, {
        scale: 3,
        ease: 'ease',
        force3D: false
      })

    } )
  }

  onGrowLeave (e) {
    this.items.forEach( (el, i) => {

      const duration = 0.3 + ( 0.04 * i )

      gsap.to(el, duration, {
        scale: 1,
        ease: 'ease',
        force3D: false
      })

    } )
  }

  onViewEnter (e) {
    this.el.classList.add('view-mode')
    document.body.classList.add('js-custom-cursor')
    this.items.forEach( (el, i) => {

      const duration = 0.3 + ( 0.04 * i )

      gsap.to(el, duration, {
        scale: 6,
        ease: 'ease',
        force3D: false
      })

    } )
  }

  onViewLeave (e) {
    this.el.classList.remove('view-mode')
    document.body.classList.remove('js-custom-cursor')
    this.items.forEach( (el, i) => {

      const duration = 0.3 + ( 0.04 * i )

      gsap.to(el, duration, {
        scale: 1,
        ease: 'ease',
        force3D: false
      })

    } )
  }

}
