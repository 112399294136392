import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

import { wcpf7ChangeErrors } from "./wcpf7ChangeErrors"

function initCustomJs ( isFirstLoad, locoScroll, uploadFiles ) {
  console.log('init custom js')

  $(document).on('click', '.js-load-btn', (e) => {
    e.currentTarget.classList.add('is-loading')
  })

  const form = document.querySelectorAll('.wpcf7-form')

  if (form.length) {
    if (!isFirstLoad) {
      console.log('wpcf7reinit')
      form.forEach(el => {
        
        try {
          wpcf7.reset(el);
        } 
        catch (ev) {
          wpcf7.init(el);
        }

      })
    }
  }
}

function destroyCustomJs () {
  console.log('destroy custom js')
}

function hideButtonLoader () {
  const form = document.querySelector('.wpcf7-form')
  if (!form) return;
  const btn = form.querySelector('button[type=submit]');
  btn.classList.remove('is-loading')
}

function customGlobalEvents ( isFirstLoad, locoScroll, uploadFiles, swup ) {

  const onInvalid = function () {
    setTimeout( function() {
      console.log('scroll to error')
      console.log(locoScroll)
      let el = document.querySelector('.wpcf7-not-valid')
    
      if (locoScroll) {
        locoScroll.locoScroll.scrollTo(el, { offset: -100 })
      } else {
        $('html').stop().animate({
          scrollTop: $(el).eq(0).offset().top - 60,
        }, 500, 'swing');
      }
      hideButtonLoader()
    }, 100);
    wcpf7ChangeErrors();
  }
  
  const onSent = function () {
    $('#modal-success').addClass('active');
    if (uploadFiles.length) {
      uploadFiles.forEach(el => {
        el.clearAll()
      });
    }
    hideButtonLoader()
  }
  
  const onSpam = function () {
    console.log('wpcf7spam')
    hideButtonLoader()
  }
  
  const onFailed = function () {
    console.log('wpcf7mailfailed')
    hideButtonLoader()
  }
  
  const onSubmit = function () {
    console.log('wpcf7submit')
    hideButtonLoader()
  }

  document.addEventListener('wpcf7invalid', onInvalid, false);

  document.addEventListener('wpcf7spam', onSpam, false);

  document.addEventListener('wpcf7mailsent', onSent, false);

  document.addEventListener('wpcf7mailfailed', onFailed, false);

  document.addEventListener('wpcf7submit', onSubmit, false);

  $(document).on('change', '.wpcf7-form > [type="file"]', wcpf7ChangeErrors);

  swup.on('animationOutDone', () => {
    document.removeEventListener('wpcf7invalid', onInvalid, false);
  
    document.removeEventListener('wpcf7spam', onSpam, false);

    document.removeEventListener('wpcf7mailsent', onSent, false);

    document.removeEventListener('wpcf7mailfailed', onFailed, false);

    document.removeEventListener('wpcf7submit', onSubmit, false);

    $(document).off('change', '.wpcf7-form > [type="file"]', wcpf7ChangeErrors);
  })
}

export { initCustomJs, destroyCustomJs, customGlobalEvents }
