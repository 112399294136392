
function scrollToId (id, duration) {
  const $target = $(id)

  if (!$target.length) return;

  document.body.classList.remove('block-scroll')

  $('html').stop().animate({
    scrollTop: $target.eq(0).offset().top - $('#swup').eq(0).offset().top,
  }, duration);
}

export { scrollToId }
