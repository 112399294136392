import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

import LocomotiveScroll from 'locomotive-scroll'

export default class LocoScroll {

  constructor () {
    this.locoScroll = null
    this.st = null
    this.init()
  }

  init () {
    this.initLocoScroll()
  }

  initLocoScroll () {
    window.scrollTo(0, 0)

    if (this.locoScroll) return;

    this.locoScroll = new LocomotiveScroll({
      el: document.querySelector('.smooth-scroll'),
      smooth: true,
      scrollFromAnywhere: true,
      tablet: {
        smooth: true
      },
      smartphone: {
        smooth: true
      }
    })
    
    this.locoScroll.on("scroll", () => {
      ScrollTrigger.update()
    });
    
    ScrollTrigger.scrollerProxy(".smooth-scroll", {
      scrollTop: (value) => {
        if (!this.locoScroll) {
          return false
        } else {
          return arguments.length ? this.locoScroll.scrollTo(value, 0, 0) : this.locoScroll.scroll.instance.scroll.y;
        }
      }, // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed"
    });

    ScrollTrigger.addEventListener("refresh", () => {
      if (this.locoScroll) {
        this.locoScroll.update()
      }
    });
    ScrollTrigger.refresh();
  }

  update () {
    if (this.locoScroll) {
      this.locoScroll.update()
    }
  }

  stop () {
    if (this.locoScroll) {
      this.locoScroll.stop()
    }
  }

  start () {
    if (this.locoScroll) {
      this.locoScroll.start()
    }
  }

  scrollTo (pos, options) {
    if (this.locoScroll) {
      this.locoScroll.scrollTo(pos, options)
    }
  }

  destroy () {
    if (this.locoScroll) {
      this.locoScroll.destroy()
      this.locoScroll = null
    }
  }

}
