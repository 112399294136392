import Parallax from "parallax-js"

let parallax = []

function initParallax () {
  const scenes = document.querySelectorAll('.js-parallax')

  if (scenes.length) {
    scenes.forEach((el, i) => {
      const dataHoverOnly = el.getAttribute('data-hover-only')

      parallax[i] = new Parallax(el, {
        hoverOnly: dataHoverOnly == 1
      })
    })
  }

}

function destroyParallax () {
  if (parallax.length) {
    parallax.forEach(el => {
      el.destroy()
      el = null
    })

    parallax = []
  }
}

export { initParallax, destroyParallax }
