import gsap, { Power3 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { timers } from 'jquery';

gsap.registerPlugin(ScrollTrigger)

export default class Header {
  constructor (el, locoScroll) {
    this.el = el
    this.locoScroll = locoScroll
    this.toggleBtn = this.el.querySelector('.js-m-header-toggle')
    this.overlay = this.el.querySelector('.js-m-header-overlay')
    this.main = document.querySelector('.js-main')
    this.menu = this.el.querySelector('.js-m-header-menu')
    this.logoText = this.el.querySelector('.js-m-header-logo-text')
    this.logoLetters = this.logoText.querySelectorAll('span')
    this.isOpen = false
    this.isAnimate = false
    this.isMobile = window.innerWidth < 1025
    this.isCollapsed = false
    this.isCollapsedForce = false
    this.isLogoTextAnimate = false
    this.logoTextTween = []

    this.st = []

    this.toggleHandler = (e) => this.toggle(e)
    this.scrollHandler = (e) => this.onScroll(e)
    this.resizeHandler = (e) => this.onResize(e)
  }

  init () {
    this.initEvents()
    this.setOverlayDefaultState()
    this.onScroll()
    this.initThemeSwitcher()
  }

  destroy () {
    this.destroyThemeSwitcher()
    this.removeEvents()
    this.isOpen = false
    this.isAnimate = false
    this.isCollapsed = false
    this.isCollapsedForce = false
    this.isLogoTextAnimate = false
    this.showLogoText()
    this.el.classList.remove('collapsed')
  }

  initEvents () {
    this.toggleBtn.addEventListener('click', this.toggleHandler)
    if (this.locoScroll) {
      this.locoScroll.locoScroll.on('scroll', this.scrollHandler)
    }
    window.addEventListener('scroll', this.scrollHandler)
    window.addEventListener('resize', this.resizeHandler)
  }

  removeEvents () {
    this.toggleBtn.removeEventListener('click', this.toggleHandler)
    window.removeEventListener('scroll', this.scrollHandler)
    window.removeEventListener('resize', this.resizeHandler)
  }

  hideLogoText () {
    if (!this.isLogoTextAnimate)
    this.isLogoTextAnimate = true

    if (this.logoTextTween.length) {
      this.logoTextTween.forEach(el => {
        el.kill()
        el = null
      })
      this.logoTextTween = []
    }
    
    for (let i = this.logoLetters.length - 1; i >= 0; i--) {
      this.logoTextTween[i] = gsap.to(this.logoLetters[i], {
        duration: 0.1,
        opacity: 0,
        ease: 'ease',
        delay: () => (this.logoLetters.length - i) * 0.01,
        onComplete: () => {
          if ( i === 0) {
            this.isLogoTextAnimate = false
            this.logoText.style.display = 'none'
          }
        }
      })
    }
  }

  showLogoText () {
    if (!this.isLogoTextAnimate)
    this.isLogoTextAnimate = true
    this.logoText.style.display = 'block'

    if (this.logoTextTween.length) {
      this.logoTextTween.forEach(el => {
        el.kill()
        el = null
      })
      this.logoTextTween = []
    }
    
    for (let i = 0; i < this.logoLetters.length; i++) {
      this.logoTextTween[i] = gsap.to(this.logoLetters[i], {
        duration: 0.1,
        opacity: 1,
        ease: 'ease',
        delay: () => i * 0.01,
        onComplete: () => {
          if ( i === this.logoLetters.length) {
            this.isLogoTextAnimate = false
          }
        }
      })
    }
  }

  initThemeSwitcher () {
    this.el.classList.remove('light')
    const darkSections = document.querySelectorAll('[data-dark-section]')
    gsap.utils.toArray(darkSections).forEach((section, i) => {

      this.st[i] = ScrollTrigger.create({
        scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
        trigger: section,
        scrub: true,
        invalidateOnRefresh: true,
        start: () => `top top+=${this.el.offsetHeight / 2}px`,
        end: () => `bottom top+=${this.el.offsetHeight / 2}px`,
        onEnter: () => {
          this.el.classList.add('light')
        },
        onLeave: () => {
          this.el.classList.remove('light')
        },
        onEnterBack: () => {
          this.el.classList.add('light')
        },
        onLeaveBack: () => {
          this.el.classList.remove('light')
        }
      })

    })
  }

  destroyThemeSwitcher () {
    if (this.st.length) {
      this.st.forEach(el => {
        el.kill()
        el = null
      })
      this.st = []
    }
  }

  onResize () {
    if (window.innerWidth > 1024) {
      if (!this.isMobile) return;
      this.isMobile = false
      if (this.isOpen) this.closeMenu(true, true)
    } else {
      if (this.isMobile) return;
      this.isMobile = true
      if (this.isOpen) this.closeMenu(true, true)
    }
  }

  onScroll (e) {
    let y = 0

    e && typeof e.scroll !== 'undefined' ? y = e.scroll.y : y = window.scrollY

    if (document.body.classList.contains('header-collapsed-force')) {
      this.el.classList.add('collapsed')
      this.isCollapsedForce = true
    }
    if (y > 100) { 
      if (this.isCollapsed) return;
      if (!this.isCollapsedForce) {
        this.el.classList.add('collapsed')
      }
      this.hideLogoText()
      this.isCollapsed = true
    } else {
      if (!this.isCollapsed) return;
      if (!this.isCollapsedForce) {
        this.el.classList.remove('collapsed')
      }
      this.showLogoText()
      this.isCollapsed = false
    }
  }

  toggle (e) {
    e.preventDefault()

    if (this.isAnimate) return

    this.isAnimate = true
    this.toggleBtn.classList.toggle('active')

    if (!this.isOpen) {
      this.hideContent()
      document.body.classList.add('block-scroll')
    } else {
      this.closeMenu()
    }

    
    gsap.fromTo(this.overlay, 1, 
      {
        yPercent: -100
      },
      {
        yPercent: 0,
        ease: Power3.easeIn,
        onStart: () => {
          gsap.set(this.overlay, {opacity: 1})
        },
        onComplete: () => {

          if (!this.isOpen) {
            this.el.classList.add('open')
            this.openMenu()
          } else {
            this.showContent()
          }
          
          gsap.fromTo(this.overlay, 0.5, 
            {
              yPercent: 0
            },
            {
              yPercent: 100,
              ease: 'ease',
              onComplete: () => {
                this.setOverlayDefaultState()
                this.isAnimate = false
                this.isOpen = !this.isOpen
              }
            }
          )

        }
      }
    )

  }

  closeMenu (force, disableAnimation) {

    let duration = 1

    if (force) {
      // this.el.classList.remove('open')
      this.toggleBtn.classList.remove('active')
    }

    if (disableAnimation) {
      duration = 0
    }
    
    if (window.scrollY > 100) this.hideLogoText()
    
    gsap.fromTo(this.menu, duration, 
      {
        opacity: 1,
        y: 0
      },
      {
        opacity: 1,
        y: 100,
        ease: Power3.easeIn,
        onComplete: () => {
          this.el.classList.remove('open')
          gsap.set(this.menu, { opacity: 0, visibility: 'hidden' })
          
          if (this.el.classList.contains('collapsed')) {
            this.hideLogoText()
          }
          
          if (force) {
            this.isOpen = false
          } else {
            document.body.classList.remove('block-scroll')
            if (this.locoScroll) {
              this.locoScroll.start()
            }
          }
        }
      }
    )
  }

  openMenu () {
    if (this.locoScroll) {
      this.locoScroll.stop()
    }
    gsap.set(this.main, { opacity: 0 })
    gsap.set(this.menu, { visibility: 'visible' })
    gsap.fromTo(this.menu, 0.5, 
      {
        opacity: 1,
        y: -80
      },
      {
        opacity: 1,
        y: 0,
        ease: 'ease',
        onComplete: () => {
          gsap.set(this.main, { opacity: 1, y: 0 })
          this.showLogoText()
        }
      }
    )
  }

  hideContent () {
    gsap.fromTo(this.main, 1, 
      {
        opacity: 1,
        y: 0
      },
      {
        opacity: 0,
        y: 100,
        ease: Power3.easeIn
      }
    )
  }

  showContent () {
    gsap.fromTo(this.main, 0.5, 
      {
        opacity: 0,
        y: -80
      },
      {
        opacity: 1,
        y: 0,
        ease: 'ease'
      }
    )
  }

  setOverlayDefaultState () {
    gsap.set(this.overlay, { yPercent: -100, opacity: 0 })
  }
}
