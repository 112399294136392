import { gsap, Power1 } from "gsap";

export default class MTitleAnimation {
  constructor (el) {
    this.el = el
    this.list = this.el.querySelector('.js-m-title-list')
    this.line = this.el.querySelector('.js-m-title-line')
    this.items = this.el.querySelectorAll('.js-m-title-item')
    this.activeItem = this.items[0]
    this.tweenLine = null
    this.tweenList = null
  }

  start () {
    const width = this.items[0].querySelector('span').offsetWidth
    this.animateLine(width, 0.6, 0.3, this.animate )
  }

  animateLine (width, duration, delay, callback) {

    this.destroyTweenLine()

    this.tweenLine = gsap.to(this.line, duration || 0.6, {
      delay: delay || 0.6,
      width: `${width}px`,
      ease: 'ease',
      onComplete: () => {
        if (callback) callback()
      }
    })
  }

  moveFirstElementDown (el) {
    const clone = el.cloneNode(true)
    el.remove()
    this.list.appendChild(clone)
  }

  setListDefaultPosition () {
    gsap.set(this.list, {
      y: 0
    })
  }

  animate = () => {

    this.destroyTweenList()

    this.items = this.el.querySelectorAll('.js-m-title-item')
    const nextElWidth = this.items[1].querySelector('span').offsetWidth
    const step = 100 / this.items.length

    this.tweenList = gsap.to(this.list, 0.9, {
      y: `-${step}%`,
      force3D: true,
      ease: Power1.easeIn,
      onStart: () => {
        this.animateLine(nextElWidth)
      },
      onComplete: () => {
        this.moveFirstElementDown(this.items[0])
        this.setListDefaultPosition()
        setTimeout(() => {
          this.animate()
        }, 2000)
      }
    })

  }

  destroyTweenLine () {
    if (this.tweenLine) {
      this.tweenLine.kill()
      this.tweenLine = null
    }
  }

  destroyTweenList () {
    if (this.tweenList) {
      this.tweenList.kill()
      this.tweenList = null
    }
  }

  destroy () {
    this.destroyTweenLine()
    this.destroyTweenList()
  }
}
