import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class Footer {

  constructor (el) {
    this.el = el
    this.wrapper = this.el.querySelector('.footer__wrapper')
    this.tween = null
  }

  init () {
    gsap.set(this.wrapper, { y: '-80%', force3D: true })

    this.tween = gsap.to(this.wrapper, {
      y: 0, 
      force3D: true,
      ease: "none",
      scrollTrigger: {
        scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
        trigger: this.el,
        start: "top bottom",
        end: "bottom bottom",
        scrub: true
      }
    })
  }

  destroy () {
    if (!this.tween) return;
    this.tween.kill()
    this.tween = null
  }

}
