import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Lottie from 'lottie-web'
import LottieOnVisible from '../../../js/lottie/lottie-on-visible'
import VideoOnVisible from '../../../js/global/video-on-visible'

gsap.registerPlugin(ScrollTrigger);

export default class MPList {

  constructor (el) {
    this.el = el
    this.items = this.el.querySelectorAll('.js-mp-list-item')
    this.list = this.el.querySelector('.js-mp-list-items')
    this.preview = this.el.querySelector('.js-mp-list-preview')
    this.previewItems = gsap.utils.toArray('.js-mp-list-preview-item')
    this.lottie = this.preview.querySelectorAll('[data-lottie]')
    this.tl = []
    this.pinSt = null
    this.clipTweens = []
    this.previewSt = null
    this.lottieAnimation = []
    this.lottieOnVisible = []
    this.videoOnVisible = []
    this.isMobile = window.innerWidth < 1025

    this.resizeHandler = () => this.onResize()
  }

  init () {
    this.initEvents()
    window.innerWidth > 1024 && window.innerHeight < window.innerWidth ? this.initDesktopView() : this.initMobileView()
  }

  initEvents () {
    window.addEventListener('resize', this.resizeHandler)
  }

  removeEvents () {
    window.removeEventListener('resize', this.resizeHandler)
  }

  onResize () {
    if (window.innerWidth > 1024 && window.innerHeight < window.innerWidth) {
      if (!this.isMobile) return;
      this.isMobile = false

      this.initDesktopView()

    } else {
      if (this.isMobile) return;
      this.isMobile = true

      this.initMobileView()

    }
  }

  initDesktopView () {
    if (this.lottieOnVisible.length) {
      this.lottieOnVisible.forEach(el => {
        el.destroy()
        el = null
      })
      this.lottieOnVisible = []
    }
   
    if (this.videoOnVisible.length) {
      this.videoOnVisible.forEach(el => {
        el.destroy()
        el = null
      })
      this.videoOnVisible = []
    }

    this.setPinedElement()
    setTimeout(() => {
      this.initAnimation()
      this.initLottie()
    }, 0)
  }

  initMobileView () {
    this.destroyInstances()

    const lottie = this.list.querySelectorAll('[data-lottie]')
    const video = this.list.querySelectorAll('[data-video]')

    if (lottie.length) {
      lottie.forEach((el, i) => {
        this.lottieOnVisible[i] = new LottieOnVisible(el)
        this.lottieOnVisible[i].init()
      })
    }

    if (video.length) {
      video.forEach((el, i) => {
        this.videoOnVisible[i] = new VideoOnVisible(el)
        this.videoOnVisible[i].init()
      })
    }
  }

  initLottie () {
    if (this.lottieAnimation.length) return;
    this.lottie.forEach((el, i) => {
      const player = el.querySelector('[data-lottie-json]')
      const path = player.getAttribute('data-lottie-json')
      el.setAttribute('data-lottie-index', i)

      this.lottieAnimation[i] = Lottie.loadAnimation({
        container: player,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: path
      })
    })
  }

  initAnimation () {

    this.previewSt = ScrollTrigger.create({
      scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
      trigger: this.el,
      once: false,
      start: () => `top bottom-=${window.innerHeight / 2}px`,
      end: () => 'bottom top',
      onEnter: () => {
        gsap.set('.clip-path-preview', { transformOrigin: 'center center' })
        gsap.to('.clip-path-preview', {
          duration: 1.5,
          scale: 1,
          ease: 'ease'
        })
      },
      onLeave: () => {
        this.stopItemAnimation(this.previewItems[this.previewItems.length - 1])
      },
      onEnterBack: () => {
        this.startItemAnimation(this.previewItems[this.previewItems.length - 1])
      },
      onLeaveBack: () => {
        this.stopItemAnimation(this.previewItems[this.previewItems.length - 1])
      }
    })

    this.previewItems.forEach((el, i) => {

      let tl = this.tl[i]
      const clipPath = el.querySelector('.clip-path-circle')

      gsap.set(clipPath, { transformOrigin: '50% 50%' })

      if (i === 0) {
        gsap.set(el, { y: 0 })
      }

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
          id: 'items-st',
          trigger: this.items[i],
          scrub: true,
          start: 'top bottom',
          end: 'center center',
          invalidateOnRefresh: true,
          onEnter: () => {
            this.startItemAnimation(el)
          },
          onEnterBack: () => {
            this.startItemAnimation(this.previewItems[i - 1])
          }
        }
      })

      tl
        .to(el, {
          y: 0,
          ease: 'ease',
          force3D: true
        }, 0)
        
        // .to(this.previewItems[i - 1], {
        //   y: -50,
        //   ease: 'ease',
        //   force3D: true,
        //   onComplete: () => {
        //     this.stopItemAnimation(this.previewItems[i - 1])
        //   }
        // }, '<')
        
        .fromTo(clipPath, { scale: 0 }, {
          scale: 1,
          ease: 'ease',
        }, 0)

    })
  }

  setPinedElement () {
    this.pinSt = ScrollTrigger.create({
      scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
      trigger: ".js-mp-list",
      start: () => `top-=${(window.innerHeight - this.preview.offsetHeight) / 2}px top`, 
      end: "bottom bottom",
      pin: ".js-mp-list-pin",
      pinSpacing: true,
      pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed",
      anticipatePin: 1,
      invalidateOnRefresh: true
    })
  }

  playVideo (el) {
    if (el.playing) {
      // el.pause()
      el.currentTime = 0
      el.play()
    } else {
      el.currentTime = 0
      el.play()
    }
  }

  stopVideo (el) {
    if (el.playing) {
      el.pause()
      el.currentTime = 0
    } else {
      el.currentTime = 0
    }
  }

  playLottie (el) {
    const player = el.querySelector('[data-lottie-json]')
    const index = el.getAttribute('data-lottie-index')
    const segments = player.getAttribute('data-segments')

    if (segments) {
      this.lottieAnimation[index].playSegments(JSON.parse(segments)[1], false)
    } else {
      this.lottieAnimation[index].play()
    }
  }

  stopLottie (el) {
    const index = el.getAttribute('data-lottie-index')

    this.lottieAnimation[index].goToAndStop(0, true)
  }

  startItemAnimation (el) {
    if (!el) return;
    const video = el.querySelector('video')
    const lottie = el.querySelector('[data-lottie]')

    if (video) {
      this.playVideo(video)
    }

    if (lottie) {
      this.playLottie(lottie)
    }
  }

  stopItemAnimation (el) {
    if (!el) return;
    const video = el.querySelector('video')
    const lottie = el.querySelector('[data-lottie]')

    if (video) {
      this.stopVideo(video)
    }

    if (lottie) {
      this.stopLottie(lottie)
    }
  }

  destroyInstances () {
    if (this.tl.length) {
      this.tl.forEach(el => {
        el.kill()
        el = null
      })
      this.tl = []
    }

    ScrollTrigger.getAll().forEach(el => {
      if (el.vars.id === 'items-st') {
        el.kill()
        el = null
      }
    })

    if (this.pinSt) {
      this.pinSt.kill()
      this.pinSt = null
    }

    if (this.previewSt) {
      this.previewSt.kill()
      this.previewSt = null
    }

    if (this.lottieAnimation.length) {
      this.lottieAnimation.forEach(el => {
        el.stop()
        el.destroy()
        el = null
      })
      this.lottieAnimation = []
    }
  }

  destroy () {
    this.removeEvents()
    this.destroyInstances()
  }

}
