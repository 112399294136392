export default class UploadFiles {

  constructor (el) {
    this.el = el
    this.input = this.el.querySelector('.js-upload-files-input')
    this.filesList = []
    this.max = 3
    this.filesNameList = this.el.querySelector('.js-upload-files-list')

    this.initDefaults()
  }

  initDefaults () {
    this.initEvents()
  }

  initEvents () {
    this.input.addEventListener('change', e => this.onChange(e))
    $(document).on('click', '.upload-files__clear', e => this.clear(e))
  }

  removeEvents () {
    this.input.removeEventListener('change', e => this.onChange(e))
    $(document).off('click', '.upload-files__clear', e => this.clear(e))
  }

  onChange (e) {
    let filesBuffer = e.target.files

    if (this.filesList.length === this.max) {
      this.el.classList.add('error')
    } else {
      this.el.classList.remove('error')
    }

    for ( let i = 0; i < filesBuffer.length; i++ ) {
      if ( this.filesList.length === this.max ) {
        this.el.classList.add('error')
        break;
      }
      this.filesList.push(filesBuffer[i])
    }
    
    this.saveFilesToInput()

    this.renderList()
  }

  saveFilesToInput () {
    const dt = new DataTransfer()
    const { files } = this.input

    for (let i = 0; i < this.filesList.length; i++) {
        const file = this.filesList[i]
        dt.items.add(file);
    }

    this.input.files = dt.files;
  }

  renderList () {
    this.filesNameList.innerHTML = ''

    this.filesList.forEach( (el, i) => {
      
      const container = document.createElement('div')
      container.classList.add('upload-files__name')
      container.setAttribute('data-index', i)

      const link = document.createElement('a')
      link.href = '#'
      link.classList.add('upload-files__clear')

      container.innerHTML = el.name
      
      container.prepend(link)

      this.filesNameList.appendChild(container)
    })
  }

  clear (e) {
    e.preventDefault()
    const target = e.currentTarget
    const container = target.closest('.upload-files__name')
    const index = container.getAttribute('data-index')
    container.remove()

    this.el.classList.remove('error')

    this.filesList.splice(index, 1)

    this.saveFilesToInput()
  }

  clearAll () {
    this.el.classList.remove('error')

    this.filesList = []

    this.saveFilesToInput()

    this.filesNameList.innerHTML = ''
  }

  destroy () {
    this.removeEvents()
  }

}

