export default class Faq {

  constructor (el) {
    this.el = el
    this.items = this.el.querySelectorAll('.js-faq-item')
    this.content = this.el.querySelectorAll('.js-faq-content')

    this.toggleHandler = (e) => this.toggle(e)
  }

  init () {
    this.setDefaults()
    this.initEvents()
  }

  destroy () {
    this.removeEvents()
  }

  initEvents () {
    this.items.forEach( el => {
      el.addEventListener('click', this.toggleHandler)
    })
  }

  removeEvents () {
    this.items.forEach( el => {
      el.removeEventListener('click', this.toggleHandler)
    })
  }

  setDefaults () {
    this.items.forEach( el => { 
      const content = el.querySelector('.js-faq-content')
      el.classList.contains('active') ? content.style.display = 'block' : content.style.display = 'none'
    })
  }

  toggle (e) {
    e.preventDefault()
    const target = e.currentTarget
    if ( target.classList.contains('active') ) {
      this.closeTab(target)
    } else {
      this.closeAllTabs()
      this.openTab(target)
    }
  }

  closeTab (el) {
    el.classList.remove('active')
    const content = el.querySelector('.js-faq-content')
    $(content).slideUp(300).promise().done(() => {
      content.style.display = 'none'
    })
  }

  openTab (el) {
    el.classList.add('active')
    const content = el.querySelector('.js-faq-content')
    $(content).slideDown(300).promise().done(() => {
      content.style.display = 'block'
    })
  }

  closeAllTabs () {
    this.items.forEach( el => { el.classList.remove('active') })
    this.content.forEach( el => { 
      $(el).slideUp(300).promise().done(() => {
        el.style.display = 'none'
      })
    })
  }

  openNewTab (el) {

  }

}
