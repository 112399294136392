import gsap, { Power3 } from 'gsap';

export default class Loader {

  constructor () {
    this.loader = document.querySelector('.js-loader')
    this.preloader = document.querySelector('.js-preloader')
    this.content = document.querySelector('.js-main')

  }
  
  complete (callback) {
    this.setPreloaderDefaultState()
    
    gsap.set(this.content, { opacity: 0, y: -80 })

    gsap.fromTo(this.preloader, 1, 
      {
        y: '-100%'
      },
      {
        y: 0,
        ease: Power3.easeIn,
        onComplete: () => {
          
          this.showContent()

          callback()
          
          gsap.fromTo(this.preloader, 0.5, 
            {
              y: 0
            },
            {
              y: '100%',
              ease: 'ease',
              onComplete: () => {
                this.setPreloaderDefaultState()
              }
            }
          )

        }
      }
    )

    this.hideLoader()

  }

  hideLoader () {
    
    gsap.fromTo(this.loader, 1, 
      {
        opacity: 1,
      },
      {
        opacity: 1,
        ease: Power3.easeIn,
        onComplete: () => {
          this.loader.style.display = 'none'
        }
      }
    )
  }

  showContent () {
    gsap.to(this.content, 0.5, 
      {
        opacity: 1,
        y: 0,
        ease: 'ease',
        onComplete: () => {
          this.content.style.transform = 'none'
        }
      }
    )
  }

  setPreloaderDefaultState () {
    gsap.set(this.preloader, { y: '-100%', opacity: 1 })
  }

}
