import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class Textarea {
  constructor (el) {
    this.el = el
    this.field = this.el.querySelector('textarea')

    this.initDefaults()
  }

  initDefaults () {
    this.initEvents()
  }

  initEvents () {
    this.field.addEventListener('input', e => this.onInput(e))
  }

  removeEvents () {
    this.field.removeEventListener('input', e => this.onInput(e))
  }

  onInput (e) {
    this.field.style.height = 'auto'
    this.field.style.height = `${this.field.scrollHeight + 2}px`
    ScrollTrigger.refresh()
  }

  destroy () {
    this.removeEvents()
  }

}

