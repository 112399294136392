import gsap from "gsap" 
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class VideoOnVisible {
  
  constructor (el) {
    this.el = el
  }

  init () {
    this.st = ScrollTrigger.create({
      scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
      trigger: this.el,
      start: () => 'top bottom',
      end: () => 'bottom+=100px top',
      onEnter: () => {
        this.onEnter()
      },
      onLeave: () => {
        this.onLeave()
      },
      onEnterBack: () => {
        this.onEnter()
      },
      onLeaveBack: () => {
        this.onLeave()
      }
    })
  }

  destroy () {
    if (this.st) {
      this.st.kill()
      this.st = null
    }
  }

  onEnter () {
    this.el.currentTime = 0
    this.el.play()
  }

  onLeave () {
    // this.el.pause()
    this.el.currentTime = 0
  }

}
