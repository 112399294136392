import Tween, { Power3 } from 'gsap';

const preloader = document.querySelector('.preloader');

function animateIn (next) {
  const container = document.querySelector('#swup');
  Tween.fromTo(container, 0.5, 
    {
      opacity: 0,
      y: -80
    },
    {
      opacity: 1,
      y: 0,
      force3D: true,
      ease: 'ease',
      onComplete: () => {
        container.style.transform = 'none'
      }
    }
  )

  Tween.fromTo(preloader, 0.5, 
    {
      y: 0
    },
    {
      y: '100%',
      force3D: true,
      ease: 'ease',
      onComplete: () => {
        Tween.set(preloader, { opacity: 0 })
        next()
      }
    }
  )
}

function animateOut (next) {
  Tween.set(preloader, { y: '-100%', opacity: 1, force3D: true })
  const container = document.querySelector('#swup');
  Tween.fromTo(container, 1, 
    {
      opacity: 1,
      y: 0
    },
    {
      opacity: 0,
      y: 100,
      force3D: true,
      ease: Power3.easeIn
    }
  )
    
  Tween.fromTo(preloader, 1, 
    {
      y: '-100%'
    },
    {
      y: 0,
      force3D: true,
      ease: Power3.easeIn,
      onComplete: next
    }
  )
}

const pageTransition = [
  {
    from: '(.*)',
    to: '(.*)',
    in: (next, infos) => {
      animateIn(next)
    },
    out: (next, infos) => {
      animateOut(next)
    }
  },
  {
    from: '(.*)',
    to: 'case-transition',
    in: (next, infos) => {
      Tween.set(preloader, { yPercent: 0, opacity: 1 })
      setTimeout(() => {
        animateIn(next)
      }, 300)
    },
    out: (next, infos) => {
      animateOut(next)
    }
  }
]

export default pageTransition;
