import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const wcpf7ChangeErrors = function () {
  console.log('wcpf7ChangeErrors function');
  setTimeout(function() {
    //input, textarea
    $(".wpcf7-form .m-feedback__field").each(function(i) {
      $(this).find('.error-msg').remove();
      $(this).find('div.input').removeClass('error');
      $(this).find('div.textarea').removeClass('error');
      if ($(this).find('.input__field').hasClass('wpcf7-not-valid')) {
              var err_text = $(this).find('.wpcf7-not-valid-tip').text();
              $(this).find('.wpcf7-form-control-wrap').after('<div class="error-msg">'+err_text+'</div>');
              $(this).find('div.input').addClass('error');
              $(this).find('.wpcf7-not-valid-tip').remove();
      } else if ($(this).find('.textarea__field').hasClass('wpcf7-not-valid')) {
              var err_text = $(this).find('.wpcf7-not-valid-tip').text();
              $(this).find('.wpcf7-form-control-wrap').after('<div class="error-msg">'+err_text+'</div>');
              $(this).find('div.textarea').addClass('error');
              $(this).find('.wpcf7-not-valid-tip').remove();
      } else {
              // $(this).removeClass('error');
      }
    });

    // file
    $(".wpcf7-form .m-feedback__upload").each(function(i) {
      $(this).find('.error-msg').remove();
      $(this).find('div.upload-files').removeClass('error');
      if ($(this).find('.wpcf7-file').hasClass('wpcf7-not-valid')) {
              var err_text = $(this).find('.wpcf7-not-valid-tip').text();
              $(this).find('.upload-files__list').after('<div class="error-msg">'+err_text+'</div>');
              $(this).find('div.upload-files').addClass('error');
              $(this).find('.wpcf7-not-valid-tip').remove();
      } else {
      }
    });

    ScrollTrigger.refresh()

  }, 10);

}

export { wcpf7ChangeErrors }
