import gsap from "gsap"

export default class FollowToCursor {

  constructor (el) {
    this.el = el
    this.x = null
    this.y = null

    this.scaleTween = null
    this.moveTween = null

    this.onMoveHandler = (e) => this.onMove(e)
    this.onLeaveHandler = (e) => this.onLeave(e)
    this.onEnterHandler = (e) => this.onEnter(e)
  }

  init () {
    this.initEvents()
  }

  initEvents () {
    this.el.addEventListener('mousemove', this.onMoveHandler)
    this.el.addEventListener('mouseleave', this.onLeaveHandler)
    this.el.addEventListener('mouseenter', this.onEnterHandler)
  }

  removeEvents () {
    this.el.removeEventListener('mousemove', this.onMoveHandler)
    this.el.removeEventListener('mouseleave', this.onLeaveHandler)
    this.el.removeEventListener('mouseenter', this.onEnterHandler)
  }

  getCoordinates (e) {
    const bound = this.el.getBoundingClientRect()

    // bound center
    const cX = bound.left + this.el.offsetWidth / 2
    const cY = bound.top + this.el.offsetHeight / 2

    // cursor position
    const x = e.clientX
    const y = e.clientY

    const diffX = cX - x
    const diffY = cY - y

    const ratio = 20

    this.x = -diffX / ratio
    this.y = -diffY / ratio
  }

  onEnter (e) {
    this.scaleTween = gsap.to(this.el, { duration: 0.9, scale: 1.05, force3D: true, ease: 'ease' })
  }

  onMove (e) {
    this.getCoordinates(e)
    this.moveTween = gsap.to(this.el, { x: this.x, y: this.y, force3D: true, ease: 'ease' })
  }

  onLeave (e) {
    this.scaleTween = gsap.to(this.el, { duration: 0.92, scale: 1, force3D: true, ease: 'ease' })
    this.moveTween = gsap.to(this.el, { duration: 0.9, x: 0, y: 0, force3D: true, ease: 'ease' })
  }

  destroy () {
    if (this.scaleTween) {
      this.scaleTween.kill()
      this.scaleTween = null
    }
    
    if (this.moveTween) {
      this.moveTween.kill()
      this.moveTween = null
    }

    this.removeEvents()
  }

}
