import Lottie from "lottie-web"
import gsap from "gsap" 
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class LottieOnVisible {
  
  constructor (el) {
    this.el = el
    this.player = this.el.querySelector('[data-lottie-json]')
    this.path = this.player.getAttribute('data-lottie-json')
    this.segments = this.player.getAttribute('data-segments')
    this.animation = null
    this.st = null
  }

  init () {
    this.initAnimation()
    this.initST()
  }
  
  initAnimation () {
    this.animation = Lottie.loadAnimation({
      container: this.player,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      path: this.path
    })
  }

  destroy () {
    if (this.animation) {
      this.animation.destroy()
      this.animation = null
    }

    if (this.st) {
      this.st.kill()
      this.st = null
    }
  }

  initST () {
    this.st = ScrollTrigger.create({
      scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
      trigger: this.el,
      start: () => 'top bottom-=100px',
      end: () => 'bottom+=100px top',
      onEnter: () => {
        this.onEnter()
        this.el.classList.add('is-active')
      },
      onLeave: () => {
        this.onLeave()
      },
      onEnterBack: () => {
        this.onEnter()
      },
      onLeaveBack: () => {
        this.onLeave()
      }
    })
  }

  onEnter () {
    if (this.segments) {
      this.animation.playSegments(JSON.parse(this.segments)[1], false)
    } else {
      this.animation.play()
    }
  }

  onLeave () {
    this.animation.pause()
  }

}
