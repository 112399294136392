import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class ScrollAnimate {

  constructor () {
    this.tweens = []
    this.batchTweens = []
    this.offset = 100
  }

  init () {
    this.createTweens()
    this.createBatch()
  }

  createTweens () {

    const items = gsap.utils.toArray(document.querySelectorAll('.js-st-animate'))

    items.forEach((el, i) => {

      if (ScrollTrigger.isInViewport(el, 0.1) && window.scrollY === 0) {
        el.classList.add('disable-animation')
      }

      this.tweens[i] = ScrollTrigger.create({
        scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
        trigger: el,
        start: () => `top bottom-=${this.offset}px`,
        once: true,
        onEnter: () => { el.setAttribute('data-animated', 'true') }, 
      })
    });
  }

  createBatch () {
    const batchContainer = document.querySelectorAll('.js-batch-container')

    if (!batchContainer.length) return;

    const items = gsap.utils.toArray(batchContainer)
    
    items.forEach( (el, i) => {

      const batchs = el.querySelectorAll('.js-batch')

      batchs.forEach(el => {
        if (ScrollTrigger.isInViewport(el, 0.1)) {
          el.classList.add('disable-animation')
        }
      })
      
      ScrollTrigger.batch(batchs, {
        onEnter: batch => {
          gsap.to(batch, { stagger: 0.3, attr: { 'data-animated': 'true' } })
        },
        id: 'st-batch',
        interval: 0.1,
        once: true,
        scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
      })
    })
  }

  destroy () {

    if (this.tweens.length) {
      this.tweens.forEach(el => el.kill()) 
      this.tweens = []
    }

    ScrollTrigger.getAll().forEach(st => st.vars.id === "st-batch" && st.kill());

  }

}

