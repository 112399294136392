import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

// ----- import Swup.js

import Swup from "swup"
import SwupJsPlugin from '@swup/js-plugin'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupScriptsPlugin from '@swup/scripts-plugin'
import SwupPreloadPlugin from '@swup/preload-plugin'

import pageTransition from "./swup/page-transition"
// import { manageScripts } from "./swup/manage-scripts"

// ----- import components

import Loader from "../blocks/components/loader/loader"
import Header from "../blocks/modules/header/header"
import Footer from "../blocks/modules/footer/footer"
import ScrollAnimate from "./global/scroll-animate"
import MPList from "../blocks/modules/mp-list/mp-list"
import MTitleAnimation from "../blocks/components/m-title/m-title"
import Textarea from "../blocks/components/textarea/textarea"
import UploadFiles from '../blocks/components/upload-files/upload-files'
import FPVideo from "../blocks/modules/fp-video/fp-video"
import CustomCursor from "../blocks/components/custom-cursor/custom-cursor"
import AboutCards from "../blocks/components/about-cards/about-cards"
import FollowToCursor from "./global/follow-to-cursor"
import LottieOnVisible from "./lottie/lottie-on-visible"
import Lines from "../blocks/components/lines/lines"
import Stages from "../blocks/modules/stages/stages"
import Stages2 from "../blocks/modules/stages-2/stages-2"
import Stages3 from "../blocks/modules/stages-3/stages-3"
import Techs from "../blocks/modules/tech-s/tech-s"
import Faq from "../blocks/modules/faq/faq"
import Tools from "../blocks/modules/tools/tools"

// ----- import functions

import LocoScroll from "./global/loco-scroll"
import { scrollToId } from "./global/scrollToId"

import { initParallax, destroyParallax } from './global/parallax'

// ----- import Custom JS

import { initCustomJs, destroyCustomJs, customGlobalEvents } from "./custom/custom"

// ----- components instances

let locoScroll = null

let loader = null
let header = null
let footer = null
let scrollAnimate = null
let mpList = null
let mTitleAnimation = null
let fpVideo = null
let customCursor = null
let aboutCards = null
let lines = null
let lottieOnVisible = []
let followToCursor = []
let textarea = []
let uploadFiles = []
let stages = []
let stages2 = []
let stages3 = []
let techs = []
let faq = []
let tools = []

// ----- flags

let isMobile = false
let isFirstLoad = true

function checkMobile () {
  isMobile = /iPad|iPhone|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/.test(navigator.userAgent)

  if(!isMobile && navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel|iPad/.test(navigator.platform)) {
    isMobile = true
  }

  if (isMobile) {
    document.body.classList.add('mobile')
  }
}

// ----- page elements

const links = document.querySelectorAll('.m-header__link')

// ----- swup.js

const swup = new Swup({
  plugins: [
    new SwupHeadPlugin(),
    new SwupJsPlugin(pageTransition),
    new SwupBodyClassPlugin(),
    new SwupScriptsPlugin({
      optin: true
    }),
    new SwupPreloadPlugin({
      preloadInitialPage: false
    })
  ],
  cache: true,
  animateHistoryBrowsing: true,
  containers: ['#swup', '#header', '.js-modal']
});

swup.on('pagePreloaded', (page) => console.log('preloaded:' + page));

swup.on('animationOutStart', () => {
  header.closeMenu(true)
})

swup.on('animationOutDone', () => {
  window.scrollTo(0, 0)
  if (locoScroll) {
    locoScroll.scrollTo(0, {duration: 0, disableLerp: true})
    locoScroll.stop()
  }
  destroy()
})

swup.on('contentReplaced', () => {
  checkMobile()
  document.body.classList.add('block-scroll')
  let hash = window.location.hash
  if (document.body.classList.contains('disable-smooth-scroll') || isMobile) {
    if (locoScroll) {
      locoScroll.destroy()
      locoScroll = null
    }
  } else {
    if (locoScroll) {
      locoScroll.update()
    } else {
      locoScroll = new LocoScroll()
    }
  }
  init()
  if (locoScroll) {
    if (hash) {
      locoScroll.scrollTo(hash, { duration: 0, disableLerp: true, offset: 80 })
    }
  } else {
    if (hash) {
      scrollToId(hash, 0)
    }
  }
});

swup.on('animationInDone', () => {
  if (locoScroll) {
    locoScroll.start()
  }
  document.body.classList.remove('block-scroll')
  customCursor.refresh()
  ScrollTrigger.refresh()
})

swup.on('clickLink', (e) => {
  let url = e.target.closest('a').getAttribute('href')
  let hash = url.split('#')[1]
  if (!hash) return;
  if (header.isOpen) {
    header.toggle(e)
  }
  if (locoScroll) {
    locoScroll.scrollTo('#' + hash, { duration: 1000, disableLerp: false, offset: 0 })
  } else {
    scrollToId('#' + hash, 1000)
  }
})

// ----- events

window.addEventListener('load', () => {
  console.log('onload 3')
  setTimeout(() => {
    loader.complete(onLoad)
  }, 0)
})

document.addEventListener('DOMContentLoaded', () => {
  onReady()
})

window.addEventListener('resize', () => {
  onResize()
})

// ----- on window load

function onLoad () {
  
  // ----- animations on scrolls

  scrollAnimate.init()

  // ----- main page title animation start

  if (mTitleAnimation) mTitleAnimation.start()

  isFirstLoad = false

  setTimeout(() => {
    document.body.classList.remove('block-scroll')
    ScrollTrigger.refresh()
  }, 300)
}

// ----- on document ready. Call once on first time load

function onReady () {
  loader = new Loader()
  checkMobile()

  // ----- custom cursor

  const customCursorEl = document.querySelector('.js-custom-cursor')
  if (customCursorEl) customCursor = new CustomCursor(customCursorEl)

  // ----- init components

  $(document).on('click', '[data-modal-open]', e => {
    e.preventDefault()
    const target = e.target.closest('[data-modal-open]')
    const id = target.getAttribute('data-modal-id')
    const modal = document.querySelector(id)

    if (modal) {
      modal.classList.add('active')
    }
  })
  
  $(document).on('click', '[data-modal-close]', e => {
    e.preventDefault()
    const target = e.target
    const modal = target.closest('.js-modal')

    if (modal) {
      modal.classList.remove('active')
    }
  })

  init()
}

// ----- init components. Call every time after route change

function init () {
  
  if (!document.body.classList.contains('disable-smooth-scroll') && !isMobile) {
    if (!locoScroll) {
      locoScroll = new LocoScroll()
    }
  }
  
  // ----- scroll animate
  
  scrollAnimate = new ScrollAnimate()
  if (!isFirstLoad) scrollAnimate.init()

  // ----- header

  const headerEl = document.querySelector('.js-m-header')
  if (headerEl) {
    header = new Header(headerEl, locoScroll)
    header.init()
  }

  // ----- footer

  const footerEl = document.querySelector('.js-footer')
  if (footerEl && !isMobile) {
    footer = new Footer(footerEl)
    footer.init()
  }

  // ----- main page title animation

  const mTitle = document.querySelector('.js-m-title')
  if (mTitle) {
    mTitleAnimation = new MTitleAnimation(mTitle)
    if (!isFirstLoad) mTitleAnimation.start()
  } 

  // ----- main page projects list

  const mpListEl = document.querySelector('.js-mp-list')
  if (mpListEl) { 
    mpList = new MPList(mpListEl) 
    mpList.init()
  }

  // ----- textarea

  const textareaEl = document.querySelectorAll('.js-textarea')

  if ( textareaEl.length ) {
    textareaEl.forEach((el, i) => {
      textarea[0] = new Textarea(el)
    })
  }

  // ----- files upload

  const uploadFilesEl = document.querySelectorAll('.js-upload-files')

  if (uploadFilesEl.length) {
    uploadFilesEl.forEach((el, i) => {
      uploadFiles[i] = new UploadFiles(el)
    })
  }

  // ----- full page parallax video

  const fpVideoEl = document.querySelector('.js-fp-video')

  if (fpVideoEl) {
    fpVideo = new FPVideo(fpVideoEl)
    fpVideo.init()
  }
  
  // ----- about cards text animation

  const aboutCardsEl = document.querySelector('.js-about-cards')

  if (aboutCardsEl) {
    aboutCards = new AboutCards(aboutCardsEl)
    aboutCards.init()
  }

  // ----- follow to cursor

  const followToCursorEl = document.querySelectorAll('.js-follow-to-cursor')

  if (followToCursorEl.length && !isMobile) {
    followToCursorEl.forEach((el, i) => {
      followToCursor[i] = new FollowToCursor(el)
      followToCursor[i].init()
    })
  }

  // ----- parallax effect

  initParallax()

  // ----- lottie on visible play

  const lottieEl = document.querySelectorAll('[data-lottie]')

  if (lottieEl.length) {
    lottieEl.forEach((el, i) => {
      if (el.closest('.js-mp-list')) return;
      lottieOnVisible[i] = new LottieOnVisible(el)
      lottieOnVisible[i].init()
    })
  }

  // ----- lines

  const linesEl = document.querySelector('.js-lines')

  if (linesEl) {
    lines = new Lines(linesEl)
    lines.init()
    lines.play()
  }
  
  // ------ services pages
  
  const stagesEl = document.querySelectorAll('.js-stages')

  if (stagesEl.length) {
    stagesEl.forEach((el, i) => {
      stages[i] = new Stages(el)
      stages[i].init()
    })
  }

  const stages2El = document.querySelectorAll('.js-stages-2')

  if (stages2El.length) {
    stages2El.forEach((el, i) => {
      stages2[i] = new Stages2(el)
      stages2[i].init()
    })
  }
  
  const stages3El = document.querySelectorAll('.js-stages-3')

  if (stages3El.length) {
    stages3El.forEach((el, i) => {
      stages3[i] = new Stages3(el)
      stages3[i].init()
    })
  }
  
  const techsEl = document.querySelectorAll('.js-tech-s')

  if (techsEl.length) {
    techsEl.forEach((el, i) => {
      techs[i] = new Techs(el)
      techs[i].init()
    })
  }
  
  const faqEl = document.querySelectorAll('.js-faq')

  if (faqEl.length) {
    faqEl.forEach((el, i) => {
      faq[i] = new Faq(el)
      faq[i].init()
    })
  }
  
  const toolsEl = document.querySelectorAll('.js-tools-slider')

  if (toolsEl.length) {
    toolsEl.forEach((el, i) => {
      tools[i] = new Tools(el)
      tools[i].init()
    })
  }

  initCustomJs(isFirstLoad, locoScroll, uploadFiles)
  customGlobalEvents(isFirstLoad, locoScroll, uploadFiles, swup)

}

// -----  destroy components and clear instances. Call every time before route change

function destroy () {
  scrollAnimate.destroy()
  scrollAnimate = null

  if (header) {
    header.destroy()
    header = null
  }
  
  if (footer) {
    footer.destroy()
    footer = null
  }

  if (mpList) {
    mpList.destroy()
    mpList = null
  }

  if (mTitleAnimation) {
    mTitleAnimation.destroy()
    mTitleAnimation = null
  }

  if (textarea.length) {
    textarea.forEach(el => {
      el.destroy()
    })
    textarea = []
  }
  
  if (uploadFiles.length) {
    uploadFiles.forEach(el => {
      el.destroy()
    })
    uploadFiles = []
  }

  if (fpVideo) {
    fpVideo.destroy()
    fpVideo = null
  }
  
  if (aboutCards) {
    aboutCards.destroy()
    aboutCards = null
  }

  if (followToCursor.length) {
    followToCursor.forEach(el => {
      el.destroy()
      el = null
    })
    followToCursor = []
  }

  if (lines) {
    lines.destroy()
    lines = null
  }

  if (lottieOnVisible.length) {
    lottieOnVisible.forEach(el => {
      el.destroy()
      el = null
    })

    lottieOnVisible = []
  }
  
  if (stages.length) {
    stages.forEach(el => {
      el.destroy()
      el = null
    })

    stages = []
  }
  
  if (stages2.length) {
    stages2.forEach(el => {
      el.destroy()
      el = null
    })

    stages2 = []
  }
  
  if (stages3.length) {
    stages3.forEach(el => {
      el.destroy()
      el = null
    })

    stages3 = []
  }
  
  if (techs.length) {
    techs.forEach(el => {
      el.destroy()
      el = null
    })

    techs = []
  }
  
  if (faq.length) {
    faq.forEach(el => {
      el.destroy()
      el = null
    })

    faq = []
  }
  
  if (tools.length) {
    tools.forEach(el => {
      el.destroy()
      el = null
    })

    tools = []
  }

  destroyParallax()
  destroyCustomJs()
}

// ----- on resize

function onResize () {
  if (!document.body.classList.contains('disable-smooth-scroll') && !isMobile) {
    ScrollTrigger.refresh()
  }
}

let portrait = window.matchMedia("(orientation: portrait)");

portrait.addEventListener("change", function(e) {
  ScrollTrigger.refresh()
})
