import Swiper, { Pagination } from 'swiper'
Swiper.use([Pagination])

export default class Tools {
  constructor (el) {
    this.el = el
    this.slider = null
    this.pagination = '.tools__pagination'
  }

  init () {
    this.sliderInit()
  }

  destroy () {
    this.sliderDestroy()
  }

  sliderInit () {
    if (this.slider) return;

    this.slider = new Swiper(this.el, {
      slidesPerView: 2,
      slidesPerColumn: 2,
      spaceBetween: 16,
      watchOverflow: true,
      observer: true,
      slidesPerColumnFill: 'row',
      pagination: {
        el: this.pagination
      },
      breakpoints: {
        1491: {
          spaceBetween: 60,
          slidesPerView: 4,
          slidesPerColumn: 2,
          slidesPerColumnFill: 'row'
        },
        1281: {
          spaceBetween: 40,
          slidesPerView: 4,
          slidesPerColumn: 2,
          slidesPerColumnFill: 'row'
        },
        1025: {
          spaceBetween: 30,
          slidesPerView: 4,
          slidesPerColumn: 2,
          slidesPerColumnFill: 'row'
        },
        768: {
          spaceBetween: 30,
          slidesPerView: 2,
          slidesPerColumn: 2,
          slidesPerColumnFill: 'row'
        }
      }
    })
  }
  

  sliderDestroy () {
    if (!this.slider) return;
    this.slider.destroy()
    this.slider = null
  }
}
