import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class FPVideo {

  constructor (el) {

    this.el = el
    this.player = this.el.querySelector('.js-fp-video-player')
    this.main = this.el.querySelector('.js-fp-video-main')
    this.overlay = this.el.querySelector('.js-fp-video-overlay')
    this.mask = this.el.querySelector('.js-fp-video-mask')
    this.caption = this.el.querySelector('.js-fp-video-caption')
    this.tl = null

  }

  init () {

    let mm = gsap.matchMedia();

    mm.add("(min-width: 1025px)", () => {

      gsap.set(this.player, { y: '-100%',})
      gsap.set(this.main, { scale: 1.4 })
      gsap.set(this.overlay, { opacity: 1 })
      gsap.set(this.mask, { y: '-22%' })
      gsap.set(this.caption, { y: '-22%', opacity: 0 })

      this.tl = gsap.timeline({
        scrollTrigger: {
          scroller: document.body.classList.contains('disable-smooth-scroll') || document.body.classList.contains('mobile') ? window : '.smooth-scroll',
          trigger: this.el,
          start: () => "top bottom",
          end: () => "bottom bottom",
          scrub: true,
          invalidateOnRefresh: true
        }
      })
  
      this.tl
        .to(this.player, { y: 0, ease: 'none', force3D: true }, 0)
        .to(this.main, { scale: 1, ease: 'none', force3D: true }, 0)
        .to(this.overlay, { opacity: 0.2, ease: 'none' }, 0)
        .to(this.mask, { y: '-56%', ease: 'none', force3D: true }, 0)
        .to(this.caption, { y: '-50%', opacity: 1, ease: 'none', force3D: true }, 0)

    })

    mm.add("(max-width: 1024px)", () => {
      gsap.set(this.player, { y: 0 })
      gsap.set(this.main, { scale: 1 })
      gsap.set(this.overlay, { opacity: 0 })
      gsap.set(this.mask, { y: '-57%' })
      gsap.set(this.caption, { y: '-50%', opacity: 1 })
      this.destroy()
    })

  }

  destroy () {
    if (!this.tl) return;
    this.tl.kill()
    this.tl = null
  }

}
